// COLORS //

$cream: #E2E8DD;
$light-green: #9DCCC0;
$green: #3D8071;
$dark-green: #204039;
$option-green: #165443e0;
$option-hov-green: #24af90;

// -------------------------------- //
// -------------------------------- //
// -----[ MOBILE SCREEN SIZE ]----- //
// -------------------------------- //
// -------------------------------- //

@media screen and (max-width: 399px) and (orientation: portrait) {
  .header-title{
    display: flex;
    flex-direction: column;
    flex: 0 1 85%;
    align-items: flex-start;
  }

  .title-main {
    font-size: calc(8px + 1vmin);
    font-weight: 600;
  }

  .title-sub {
    font-size: calc(8px + 1vmin);
    font-weight: 500;
    padding-right: 10px;
    padding-left: 10px;
  }

}

@media screen and (max-width: 599px) and (orientation: portrait) {

  // APP //

  .app {
    font-family: 'Roboto Slab', sans-serif;
    font-size: calc(8px + 2vmin);
    color: #E2E8DD;
    min-width: auto;
  }

  // HEADER BAR //

  .app-header {
    background-color: #3D8071;
    position: sticky;
    top: 0;
    height: 3.7rem;
    padding: 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    z-index: 2;
  }

  .menu-button {
    flex: 0 1 10%;
    transform: scale(.8);
    > * {
      cursor: pointer;
    }
  }

  .header-title{
    display: flex;
    flex-direction: row;
    flex: 0 1 85%;
    align-items: flex-start;
  }

  .title-main {
    font-size: calc(9px + 1.5vmin);
    font-weight: 600;
  }

  .title-sub {
    font-size: calc(9px + 1.5vmin);
    font-weight: 500;
    padding-right: 35px;
  }

  .social-media-container {
    transform: scale(.35);
    position: absolute;
    left: 87.5%;
    display: inline-flex;
    flex: 0 0 0%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .social-icon {
    margin: 0 5px;
  }

  // FOOTER CONTAINER //

  .footer-container {
    position: sticky;
    bottom: 0;
    background-color: $green;
    color: $cream;
    font-size: calc(8px + 1vmin);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height : 35px;
  }

  .footer-container>span {
    height: 15px;
    transform: scale(.9);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 0;
    >p, a {
      transform: scale(.8);
      color: $cream;
      display: inline;
      position: relative;
      bottom: 0;
      margin-block-end: 0;
      margin-block-start: 0;
    }
    p.large-size {
      display: none;
    }
  }

  a.footer-linkedin-anchor {
    margin: 0;
  }

  img.atom-logo,
  img.javascript-logo,
  img.react-logo,
  img.sass-logo {
    transform: scale(.5);
    position: relative;
    bottom: 0;
    margin: 0;
  }

  // APP MAIN //

  .app-main {
    background-color: $dark-green;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: auto;
  }

  // MENU DRAWER //

  .menu-drawer-container-active {
    position: absolute;
    height: auto;
    width: auto;
    background-color: rgb(40, 102, 87);
    padding: 10px 0 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    animation: slide-bottom 0.5s ease-in;
    -webkit-animation: slide-bottom 0.5s ease-in;
  }

  .menu-drawer-container-inactive {
    position: absolute;
    height: 0;
    width: 0;
    background-color: rgb(40, 102, 87);
    padding: 10px 0 5px 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: slide-top 1s ease-out;
    -webkit-animation: slide-top 1s ease-out;
  }

  .drawer-options-container {
    background-color: rgb(40, 102, 87);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
  }

  .option-link {
    text-decoration: none;
  }

  .menu-drawer-option {
    transform: scale(.7);
    background-color: $cream;
    color: $option-green;
    font-size: calc(12px + 1vmin);
    font-weight: 600;
    width: auto;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &:hover {
      font-size: calc(12.5px + 1vmin);
      font-weight: 700;
      padding: 0.4rem 0.7rem;
      color: $option-hov-green;
      transition: 0.2s;
      cursor: pointer;
    }
    &:not(:hover) {
      font-weight: 600;
      padding: 0.4rem 0.7rem;
      transition: 0.2s;
    }
  }

  .option-icon {
    display: none;
    height: 0;
    width: 0;
  }

  .option-text {
    transform: scale(1.1);
    display: inline;
    // margin: 5px 5px 0px 5px;
  }

  .detail-container {
    height: inherit;
    min-height: 700px;
    padding: 5px 5px 50px 5px;
    flex: 0 1 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // RESUME PAGE //

  .resume-page-container {
    height: inherit;
    width: inherit;
    padding: 5px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a.resume-blob {
    display: none;
    border: none;
    height: 0;
    width: 0;
  }

  .resume-header {
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .about-contact-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0 0 0;
    >a {
      margin: 0px 0px 10px 0px;
      color: $cream;
    }
  }

  .resume-doc {
    margin: 5px 15px 5px 15px;
    padding: 3px;
    border: 3px outset #E2E8DD;
    z-index: 0;
    width : 85vw;
    height: 1000px;
    overflow: scroll;
  }

  .resume-download-wrapper {
    transform: scale(.9);

    width: auto;
    background-color:rgba(61, 128, 113, .6);
    margin-top: 15px;
    padding: 5px 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .resume-download-wrapper:hover{
    transform: scale(.9);

    background-color:rgba(61, 128, 113, 1);
    color: #E2E8DD;
    cursor: pointer;
  }

  .download-text {
    font-weight: 600;
    margin-right: 3px;
  }

  .download-icon {
    display: inline;
  }

  // PROJECTS PAGE //

  .projects-page-container {
    height: inherit;
    width: 90vw;
    padding: 5px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    > span, section {
      padding: 5px 0;
    }
    >h2 {
      margin-block-start: 2.5px;
      margin-block-end: 2.5px;
      margin-inline-start: 2.5px;
      margin-inline-end: 2.5px;
      transform: scale(.9);
    }
  }

  .projects-header-container {
    width: 90vw;
    display: flex;
    margin: 0 0 5px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > span {
      width: auto;
      margin: 7.5px 15px;
      padding: 2.5px 15px;
      border-radius: 25px;
      cursor: pointer;
    }
  }

  .active-project-option {
    color:rgba(61, 128, 113, 1);
    background-color: #E2E8DD;
    font-weight: 700;
    box-shadow: 0 0 10px 7.5px rgba(61, 128, 113, 1);
  }

  .inactive-project-option {
    // background-color: rgba(61, 128, 113, 0.6);
    background-color: rgba(61, 128, 113, .6);
    &:hover {
      background-color: rgba(61, 128, 113, 1);
      color: #E2E8DD;
    }
  }

  .summary-demo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .projects-summary-container {
    align-self: center;
    margin: 10px 0 0 0;
    width: 90vw;
    text-align: left;
    line-height: 1.2;
    .summary-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;
      font-size: calc(10px + 2vmin);
      font-weight: 700;
    }
    .summary-links {
      padding-left: 5px;
      font-size: calc(10px + 1.25vmin);
      > a {
        padding: 0 2.5px;
        color: $cream
      }
    }
    .summary-tech {
      font-size: calc(8px + 1.25vmin);
    }
    .summary-bullets {
      font-size: calc(8px + .85vmin);
    }
  }


  .projects-video-container {
    height: auto;
    width: auto;
    box-shadow: 0 0 6px 2px $cream;
    background: #E2E8DD;
    color: $cream;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
    >.demo-picture {
      height: 275px;
      width: 85vw;
      margin: 5px 5px 0 5px;
      box-shadow: 0 0 3px 1px $dark-green;
    }
  }

  // EXPERIENCE PAGE //

  .experience-container  {
    height: inherit;
    width: inherit;
    padding: 5px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .experience-header {
    padding: 30px 5px 30px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .experience-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .hover-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover,
    &:focus {
      > .hover-block {
        // box-shadow: 0 0 10px 7.5px $green;
      }
      color: #E2E8DD;
      -webkit-animation: slide-left .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  	          animation: slide-left .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    &:not(:hover),
    &:not(:focus) {
      -webkit-animation: slide-right .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  	          animation: slide-right .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

  .hover-block {
    height: 30px;
    width: 3px;
    background-color: #E2E8DD;
    &:not(:hover) {
      animation: shrink-vert .20s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

  .active-hover-block {
    box-shadow: 0 0 10px 7.5px $green;
    &:not(:hover) {
      animation: grow-vert .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }


  .exp-project-group {
    width: 70vw;
    display: flex;
    flex-direction: column;
    padding: 30px 5px 30px 50px;
    margin: 10px;
  }

  .exp-line-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }

  .exp-bullets-active {
    font-size: calc(10px + 1.5vmin)

  }

  .exp-bullets-inactive {
    display: none;
  }

  // ABOUT PAGE //

  .about-container {
    height: inherit;
    width: 95vw;
    padding: 5px 0px 5px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .about-header {
    font-size: calc(10px + 1.5vmin );
    margin: 20px 5px 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-main {
    font-size: calc(8px + 1vmin );
    margin: 0px 20px 20px 20px;
  }

  // ABOUT FORM //

  label,
  input,
  textarea,
  button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    &:focus {
      outline: none;
    }
  }

    .about-form-wrapper {
    width: 70vw;
    border-radius: 5px;
    box-shadow:  0  0  2px 3px $cream;
    margin: 5px 0px;
    padding: 15px 30px;
  }

  .about-form {
    width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >span {
      width: 70vw;
      border: 1px solid $cream;
      border-radius: 5px;
      margin: 2px 0;
      padding: 5px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      >*{
        margin: 0 10px;
      }
      >label {
        font-size: calc(8px + 2vmin);
        font-weight: 400;
        padding: 4px 0 2px 0;
        align-self: flex-start;
      }
      >input{
        font-family: 'Roboto Slab', sans-serif;
        font-weight: 400;
        background-color: $dark-green;
        color: $cream;
        font-size: calc(8px + 1.5vmin);
        align-self: flex-end;
        height: 1rem;
        width: 37.5vw;
        margin: 3px 10px;
        padding: 2px 6px 4px 6px;
        outline: none;
        border: none;
        border-radius: 3px;
        box-shadow: 0  0  2px 1px $light-green;
        &:active,
        &:focus, {
          box-shadow: 0  0  2px 1px $cream;

        }
      }
    }
  }

  .contact-label {
    font-size: calc(14px + 2vmin);
    margin-bottom: 5px;
  }

  .about-form-message {
    display: inline-flex;
    flex-direction: row;
    font-size: calc(8px + 1vmin);
    border-top: 1px double $cream;
    border-bottom: 1px double $cream;
    justify-content: center;
    font-weight: 600;
    margin-bottom: 5px;
  }

  img.about-message-loading {
    width: 15px;
    height: 15px;
    padding: 2.5px 0 1.5px 2.5px;
  }

  img.not-loading {
    display: none;
    width: 0;
    height: 0;
  }

  .about-text {
    width: 64vw;
    height: 150px;
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 10px 10px;
    color: $cream;
    font-size: calc(8px + 1vmin);
    background-color: $dark-green;
    box-shadow: 0 0 2px 1px $light-green;
    border: none;
    border-radius: 3px;
    resize: none;
    &:not(:active),
    &:active,
    &:not(:focus)
    &:focus {
      font-size: calc(8px + 1vmin);
      box-shadow: 0 0 2px 1px $cream;
    }
    &::placeholder {
      font-size: calc(8px + 1vmin);
      color: $cream;
    }
  }

  .about-button {
    font-family: 'Roboto Slab', sans-serif;
    font-weight: 600;
    background-color: $cream;
    color: #165443e0;
    outline: none;
    height: 20px;
    width: 80px;
    margin-bottom: 5px;
    font-size: calc(8px + 1vmin);
    letter-spacing: 1px;
    border: none;
    border-radius: 5px;
    box-shadow: 0  0  3px 3px $cream;
    transition: 0.2s;
    &:hover {
      padding: 1px 0 0 0;
      box-shadow: 0  0  3px 2px $cream;
      width: 90px;
      font-weight: 700;
      color: #24af90;
      transition: 0.2s;
      cursor: pointer;
    }
    &:active,
    &:focus, {
      padding: 2.5px 0 0 0;
      box-shadow: 0  0  3px 3px seagreen;
    }
    &:not(:active),
    &:not(:focus) {
      padding: 0 0 0 0;
      box-shadow: 0  0  3px 3px $cream;
      width: 100px;
      font-weight: 600;
      color: #165443e0;
      transition: 0.2s;
    }
  }

}

// --------------------------------- //
// --------------------------------- //
// -----[ REGULAR SCREEN SIZE ]----- //
// --------------------------------- //
// --------------------------------- //


// APP //

@media screen and (min-width: 600px) {

  .app {
    font-size: calc(10px + 2vmin);
    color: #E2E8DD;
    min-width: 1000px;
    font-family: 'Roboto Slab', sans-serif;
  }

  // HEADER BAR //

  .app-header {
    background-color: #3D8071;
    position: sticky;
    top: 0;
    height: 5.5rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
    box-shadow: 0 1px 5px 5px $green;
  }

  .menu-button {
    flex: 0 1 10%;
    > * {
      cursor: pointer;
    }
  }

  .header-title{
    display: flex;
    flex-direction: column;
    flex: 0 1 70%;
  }

  .title-main {
    font-size: calc(10px + 3vmin);
    letter-spacing: .15rem;
    font-weight: 700;
  }

  .title-sub {
    font-weight: 500;
  }

  .social-media-container {
    flex: 0 1 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .social-icon {
    margin: 0 5px;
  }

  // FOOTER CONTAINER //

  .footer-container {
    position: sticky;
    bottom: 0;
    background-color: $green;
    color: $cream;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height : 60px;
    box-shadow: 0 -1px 5px 5px $green;
  }


  .footer-container>span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 2.5px;
    >p, a {
      color: $cream;
      display: inline;
      position: relative;
      bottom: 5px;
      margin-block-end: 0;
      margin-block-start: 0;
    }
    >p.small-size {
      display: none;
    }
  }

  a.footer-linkedin-anchor {
    margin: 0 10px;
  }
  img.atom-logo {
    position: relative;
    bottom: 5px;
    margin: 0 5px;
  }

  img.javascript-logo,
  img.react-logo,
  img.sass-logo {
    position: relative;
    bottom: 5px;
    margin: 0 10px;
  }

  // APP MAIN //

  .app-main {
    background-color: $dark-green;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: auto;
  }

  // MENU DRAWER //

  .menu-drawer-container-active {
    position: sticky;
    top: 5.5rem;
    background-color: rgb(40, 102, 87);
    height: auto;
    max-height: 100px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 11vw;
    box-shadow: 0 1px 5px 5px #286657;
    z-index: 1;
  }

  .menu-drawer-container-inactive {
    position: sticky;
    top: 5.5rem;
    background-color: rgb(40, 102, 87);
    height: 0;
    max-height: 100px;
    width: auto;
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 8.5rem;
  }

  .drawer-options-container {
    background-color: rgb(40, 102, 87);
    padding: 15px 10px 5px 10px;
    display: flex;
    flex-direction: row;
  }

  .option-link {
    text-decoration: none;
  }

  .menu-drawer-option {
    color: $option-green;
    font-size: calc(12px + 1vmin);
    font-weight: 600;
    margin: 0.2rem 0.3rem;
    padding: 0.3rem 1.0rem;
    width: auto;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    background-color: $cream;
    &:hover {
      font-size: calc(12.5px + 1vmin);
      font-weight: 700;
      padding: 0.3rem 1.4rem;
      color: $option-hov-green;
      cursor: pointer;
      transition: 0.2s;
    }
    &:not(:hover) {

      font-weight: 600;
      padding: 0.3rem 1.0rem;
      transition: 0.2s;
    }
  }

  .option-icon {
    display: inline;
    padding-top: 0px;
  }

  .option-text {
    display: inline;
    padding-left: 5px;
    align-self: center;
  }

  .detail-container {
    height: inherit;
    min-height: 700px;
    padding: 5px 5px 50px 5px;
    flex: 0 1 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // RESUME PAGE //

  .resume-page-container {
    height: inherit;
    width: inherit;
    padding: 5px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .resume-header {
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .about-contact-line>a {
    margin: 0 7.5px;
    color: $cream;
  }

  .resume-doc {
    margin: 15px;
    padding: 3px;
    border: 3px outset #E2E8DD;
    z-index: 0;
  }

  .resume-download-wrapper {
    width: auto;
    background-color:rgba(61, 128, 113, .6);
    margin-top: 15px;
    padding: 5px 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .resume-download-wrapper:hover{
    background-color:rgba(61, 128, 113, 1);
    color: #E2E8DD;
    cursor: pointer;
  }

  .download-text {
    font-weight: 600;
    margin-right: 3px;
  }

  .download-icon {
    display: inline;
  }

  // PROJECTS PAGE //

  .projects-page-container {
    height: inherit;
    width: inherit;
    padding: 5px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    > span, section {
      padding: 10px 0;
    }
  }

  .projects-header-container {
    width: 1050px;
    display: flex;
    margin: 10px 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > span {
      width: auto;
      margin: 0 15px;
      padding: 5px 15px;
      border-radius: 25px;
      cursor: pointer;
    }
  }

  .active-project-option {
    color:rgba(61, 128, 113, 1);
    background-color: #E2E8DD;
    font-weight: 700;
    box-shadow: 0 0 10px 7.5px rgba(61, 128, 113, 1);
  }

  .inactive-project-option {
    // background-color: rgba(61, 128, 113, 0.6);
    background-color: rgba(61, 128, 113, .6);
    &:hover {
      background-color: rgba(61, 128, 113, 1);
      color: #E2E8DD;
    }
  }

  .summary-demo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .projects-summary-container {
    align-self: center;
    margin: 10px 0;
    width: 800px;
    text-align: left;
    line-height: 1.2;
    .summary-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;
      font-size: calc(10px + 3.5vmin);
      font-weight: 700;
    }
    .summary-links {
      padding-left: 10px;
      font-size: calc(16px + 2vmin);
      > a {
        padding: 0 7.5px;
        padding-bottom: 5px;
        color: $cream;

      }
    }
    .summary-tech {
      font-size: calc(10px + 1.5vmin);
    }
    .summary-bullets {
      font-size: calc(10px + 1.5vmin);
    }
  }

  .projects-video-container {
    height: auto;
    width: auto;
    box-shadow: 0 0 7.5px 2.5px $cream;
    background: #E2E8DD;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    >.demo-picture {
      margin: 15px 15px 0px 15px;
      box-shadow: 0 0 7.5px 2.5px $dark-green;
        &:hover {
          box-shadow: 0 0 6px 2px $green;
        }
      }
    }

  // EXPERIENCE PAGE //

  .experience-container  {
    height: inherit;
    width: inherit;
    padding: 5px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .experience-header {
    padding: 30px 5px 30px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .experience-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .hover-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      > .hover-block {
        height: 30px;
        box-shadow: 0 0 10px 7.5px $green;
      }
      color: #E2E8DD;
      -webkit-animation: slide-left .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  	          animation: slide-left .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    &:not(:hover) {
      -webkit-animation: slide-right .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  	          animation: slide-right .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

  .hover-block {
    height: 30px;
    width: 3px;
    background-color: #E2E8DD;
    &:not(:hover) {
      animation: shrink-vert .20s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

  .active-hover-block {
    box-shadow: 0 0 10px 7.5px $green;
    &:not(:hover) {
      animation: grow-vert .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

  .exp-project-group {
    width: 70vw;
    display: flex;
    flex-direction: column;
    padding: 30px 5px 30px 50px;
    margin: 10px;
  }

  .exp-line-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }

  .exp-bullets-active {
    font-size: calc(10px + 1.5vmin)

  }

  .exp-bullets-inactive {
    display: none;
  }

  // ABOUT PAGE //

  .about-container {
    height: inherit;
    width: inherit;
    padding: 5px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .about-header {
    font-size: 2rem;
    margin: 20px 5px 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-main {
    font-size: 1.4rem;
    margin: 0px 20px 20px 20px;
  }

  // ABOUT FORM //

  label,
  input,
  textarea,
  button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:focus {
      outline: none;
    }
  }

  .about-form-wrapper {
    border-radius: 5px;
    box-shadow:  0  0  3px 4px $cream;
    margin: .3rem .2rem;
    padding: .7rem 2rem;
  }

  .about-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >span {
      min-width: 36rem;
      border: 1px solid $cream;
      border-radius: 5px;
      margin: 3px 0;
      padding: 5px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      >*{
        margin: 0 10px;
      }
      >label {
        font-weight: 500;
        padding: 4px 0 2px 0;
        align-self: flex-start;
      }
      >input{
        font-family: 'Roboto Slab', sans-serif;
        font-weight: 500;
        background-color: $dark-green;
        color: $cream;
        font-size: 1.5rem;
        align-self: flex-end;
        height: 1.8rem;
        width: 25rem;
        margin: 3px 10px;
        padding: 2px 6px;
        outline: none;
        border: none;
        border-radius: 3px;
        box-shadow: 0  0  3px 2px $light-green;
        &:active,
        &:focus, {
          box-shadow: 0  0  3px 2px $cream;

        }
      }
    }
  }

  .contact-label {
    font-size: 2rem;
    margin-bottom: 5px;
  }

  .about-form-message {
    width: auto;
    display: inline-flex;
    flex-direction: row;
    font-size: 1.2rem;
    border-top: 1px double #E2E8DD;
    border-bottom: 1px double #E2E8DD;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 600;
    margin-bottom: 5px;
    padding: 5px 0;
  }

  img.about-message-loading {
    padding: 0 10px;
  }

  img.not-loading {
    display: none;
    width: 0;
    height: 0;
  }

  .about-text {
    font-size: 18px;
    width: 553px;
    height: 200px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 10px;
    color: $cream;
    font-size: 1.5rem;
    background-color: $dark-green;
    box-shadow: 0 0 3px 3px $light-green;
    border-radius: 3px;
    resize: none;
    &:active,
    &:not(:active),
    &:focus,
    &:not(:focus) {
      font-size: 18px;
      box-shadow: 0 0 3px 3px $cream;
    }
    &::placeholder {
      font-size: 18px;
      color: $cream;
    }
  }

  .about-button {
    font-family: 'Roboto Slab', sans-serif;
    font-weight: 600;
    color: #165443e0;
    outline: none;
    height: 2.2rem;
    width: 7rem;
    margin-bottom: 10px;
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    box-shadow: 0  0  3px 3px $cream;
    transition: 0.2s;
    &:hover {
      padding: 1px 0 0 0;
      box-shadow: 0  0  3px 2px $cream;
      width: 7.5rem;
      font-weight: 700;
      color: #24af90;
      transition: 0.2s;
      cursor: pointer;
    }
    &:active,
    &:focus, {
      padding: 2.5px 0 0 0;
      box-shadow: 0  0  3px 3px seagreen;
    }
  }

}

//                             //
// --------------------------- //
//          KEYFRAMES          //
// --------------------------- //
//                             //

// EXPERIENCE PAGE //

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}

// NAVIGATION BAR //

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
  }
}

@-webkit-keyframes grow-vert {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 2);
            transform: scale(1, 2);
  }
}

// @keyframes grow-vert {
//   0% {
//     -webkit-transform: scale(1, 1);
//             transform: scale(1, 1);
//   }
//   100% {
//     -webkit-transform: scale(1, 3);
//             transform: scale(1, 3);
//   }
// }

@keyframes grow-vert {
  0% {
    height: 30px;
  }
  100% {
    height: 200px;
  }
}

@keyframes shrink-vert {
  0% {
    height: 200px;
  }
  100% {
    height: 30px;
  }
}
